import { Box, IconButton, Text } from "@chakra-ui/react";
import { useEditorStore } from "../../stores/editor-store";
import { CloseIcon } from "@chakra-ui/icons";
import Object3DEditor from "../position-editor";
import VideoControls from "../item-list/item-view/video-controls";
import ModelControls from "../item-list/item-view/model-controls";

export default function ItemEditSidebar() {
    const selectedItem = useEditorStore(state => state.items[state.selectedItemId] || null);
    const deselectItem = useEditorStore(state => state.deselectItem);

    return selectedItem && (
        <Box
            maxWidth="30vw"
            p={4} // Padding added for internal spacing
            borderRadius="lg" // Rounded corners for a modern look
            backgroundColor="rgba(255, 255, 255, 0.8)" // Adjusted opacity for better readability
            backdropFilter="blur(15px)" // Increased blur effect
            border="1px solid rgba(255, 255, 255, 0.3)" // Subtle border adjustment
            overflowY="auto" // Changed from scroll to auto for cleaner UX
            position="absolute"
            zIndex={10}
            top={"10dvh"}
            right={0}
            // height="100vh" // Ensuring it covers full vertical height
            boxShadow="2xl"
            rounded="md"
        >
            <IconButton
                aria-label="Close right sidebar"
                icon={<CloseIcon />}
                position="absolute"
                top="10px" // Adjusted positioning for better alignment
                right="10px"
                onClick={() => {
                    deselectItem()
                    AFRAME.INSPECTOR.selectEntity(null);

                }}
                size="lg" // Larger button for easier interaction
                isRound // Optional: Round button style
            />
            <Text fontSize="lg" mb={4}>Editing {selectedItem.id}</Text>
            <Object3DEditor item={selectedItem} />
            {selectedItem.type === "videoPlane" && <VideoControls item={selectedItem} />}
            {selectedItem.type === "3dObject" && <ModelControls item={selectedItem} />}
        </Box>
    );
}

import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import CameraToolbar from './camera-toolbar';
import TransformToolbar from './transform-toolbar';
import ScenePlayButton from './scene-play'; // Make sure to import the ScenePlayButton
import { useEditorStore } from '../../../stores/editor-store';

const BottomBanner = ({ isBig }) => {
    const isSceneLoaded = useEditorStore(state => state.isSceneLoaded);
    const editorWidth = isBig ? "80dvw" : "95dvw";

    return (
        <Box
            position="absolute"
            bottom="0"
            right="0"
            zIndex="banner"
            w={editorWidth}
            borderRadius="lg" // Rounded corners for a modern look
            backgroundColor="rgba(255, 255, 255, 0.8)" // Adjusted opacity for better readability
            backdropFilter="blur(15px)" // Increased blur effect
            border="1px solid rgba(255, 255, 255, 0.3)" // Subtle border adjustment
        >
            <Flex justify="space-between" align="center" p={4} gap="4">
                <CameraToolbar />
                <TransformToolbar />
                {isSceneLoaded && <ScenePlayButton />}
            </Flex>
        </Box>
    );
};

export default BottomBanner;

import { VStack, Collapse, IconButton, Text, Box } from "@chakra-ui/react";
import ModalAndWizard from "../../wizzard-modal";
import ItemList from "../../item-list/item-list";
import Object3dProvider from "../../../providers/object3d-provider";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { FaPlus } from "react-icons/fa";

export default function Editor({ isOpen, onToggle }: any) {
    return (
        <VStack align="stretch" h="100%" position="relative" w="100%" justifyContent={isOpen? "space-between": "flex-end"}>

            <Collapse in={isOpen} animateOpacity style={{ height: '100%', overflowY: "scroll", width: "100%" }}>
                <Object3dProvider>

                    <ModalAndWizard />
                   
                    <ItemList />
                </Object3dProvider>

            </Collapse>

        </VStack >
    );
}

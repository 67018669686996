import React, { useState } from 'react';
import { Button, Input, Box, Text, VStack, Image } from '@chakra-ui/react';

const ImageUpload = ({ setFile, src, file }) => {

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file)
        }
    };

    return (
        <VStack spacing={4}>
            <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                hidden
                id="image-upload"
            />
            <Box>
                <label htmlFor="image-upload">
                    <Button as="span" size="md" cursor="pointer">
                        Upload Image
                    </Button>
                </label>
            </Box>
            {file && (
                <Text>File: {file.name}</Text>
            )}
            {src && (
                <Image
                    src={src}
                    alt="Image preview"
                    boxSize="200px"
                    objectFit="cover"
                />
            )}
        </VStack>
    );
};

export default ImageUpload;

import React from 'react';
import { VStack, Spacer } from '@chakra-ui/react';
import Editor from './sidebar-tree-content';
import SidebarFooter from './sidebar-footer';
import SidebarHeader from './sidebar-header';

const Sidebar = ({ isOpen, onToggle }) => {
    const editorWidth = isOpen ? "20vw" : "5vw";

    return (
        <VStack
            width={editorWidth}
            height="100vh"
            position="fixed"
            zIndex={10}
            backgroundColor="rgba(255, 255, 255, 0.3)"
            boxShadow="2xl"
            backdropFilter="blur(10px)"
            border="1px solid rgba(255, 255, 255, 0.2)"
            overflowY="scroll"
            spacing={4}
        >
            <SidebarHeader isOpen={isOpen} />
            {/* Using Spacer with calculated height to push Editor down */}
            <Editor isOpen={isOpen} onToggle={onToggle} />
            <SidebarFooter isOpen={isOpen} onToggle={onToggle} />
        </VStack>
    );
};

export default Sidebar;

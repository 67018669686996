import { useRef } from 'react'
import { Object3DContext, Object3DProps, Object3DStore, createObject3DStore } from '../stores/object3d-store'

type Object3dProviderProps = React.PropsWithChildren<Partial<Object3DProps>>

export default function Object3dProvider({ children, ...props }: Object3dProviderProps) {
    const storeRef = useRef<Object3DStore>()
    if (!storeRef.current) {
        storeRef.current = createObject3DStore(props)
    }
    return (
        <Object3DContext.Provider value={storeRef.current}>
            {children}
        </Object3DContext.Provider>
    )
}
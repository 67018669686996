import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";

const SidebarFooter = ({ isOpen, onToggle }: any) => {
    return (
        <Box
            alignSelf="flex-end"
            p={2}  // Padding around the box
            width="full"  // Take full width to maintain layout consistency
        >
            <IconButton
                aria-label="Toggle Sidebar"
                icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                onClick={onToggle}
                size="lg"
            />
            <Text fontSize="sm" mt={1}>Collapse</Text>
        </Box>
    );
};
export default SidebarFooter
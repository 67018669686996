// src/components/UI.jsx
import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import TopBanner from './target-uploader/top-banner';
import Sidebar from './right-sidebar/sidebar-tree';
import ItemEditSidebar from './item-edit-sidebar';
import BottomBanner from './toolbar/bottom-banner';

const UI = () => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

    return (
        <>
            <TopBanner isBig={isOpen} />
            <Sidebar isOpen={isOpen} onToggle={onToggle} />
            <ItemEditSidebar />
            <BottomBanner isBig={isOpen} />
        </>
    );
};

export default UI;

import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Input
} from '@chakra-ui/react';
import TargetUploader from './target-uploader';

const FileUploadModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload File</ModalHeader>
                <ModalBody>
                    <TargetUploader/>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="ghost">Upload</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FileUploadModal;

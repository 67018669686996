import React from 'react';
import { Box, useRadio, UseRadioProps, useRadioGroup, HStack, Icon, Text } from '@chakra-ui/react';
import { MdImage, MdOndemandVideo } from 'react-icons/md';
import { Gi3DMeeple } from "react-icons/gi";


interface RadioCardProps extends UseRadioProps {
    children: React.ReactNode;
    label: string; // Add a label prop for the text
}

const RadioCard: React.FC<RadioCardProps> = ({ label, ...props }) => {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
        <Box as="label" w="200px" h="200px">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: "blue.500",
                    color: "white",
                    borderColor: "blue.500",
                }}
                _focus={{
                    boxShadow: "outline",
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column" // Set the direction to column to stack the icon and text
                h="100%"
            >
                {props.children}
                <Text mt={2}>{label}</Text> {/* Add the text label below the icon */}
            </Box>
        </Box>
    );
};

// Wrapping the RadioCard components with RadioGroup from Chakra UI
const RadioCardsGroup: React.FC<{ onChange: (value: string) => void, value: string }> = ({ onChange, value }) => {

    const options = {
        videoPlane: { icon: MdOndemandVideo, label: 'Video Plane' },
        imagePlane: { icon: MdImage, label: 'Image Plane' },
        '3dObject': { icon: Gi3DMeeple, label: '3D Object' },
    };

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'objectType',
        defaultValue: 'videoPlane',
        value: value ? value : options[0],
        onChange,
    });

    const group = getRootProps();


    return (
        <HStack {...group}>
            {Object.entries(options).map(([value, { icon, label }]) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio} label={label}>
                        <Icon as={icon} boxSize="50%" />
                        {/* Text label is passed via the `label` prop to the RadioCard */}
                    </RadioCard>
                );
            })}
        </HStack>
    );
};

export default RadioCardsGroup;

import * as React from "react";
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react";
import SceneEditor from "./components/scene-editor";


export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <SceneEditor />
    </ChakraProvider >
  );
}

import 'aframe';
import 'aframe-extras';
import Viewer from "./viewer";
import StartScreenModal from "./start-modal";
import "../lib/index"
import UI from "./controls/ui";
import { Box } from '@chakra-ui/react';
export const SceneEditor = () => {
    return <>
        <Box textAlign="center" fontSize="xl" h="100vh" width="100vw">
            <UI />
            <Box w={"100vw"} height="100%" overflow="hidden" position={"relative"}>
                <Viewer />
            </Box>
            {/* <ItemEditSidebar /> */}
        </Box>
        <StartScreenModal />
    </>
}

export default SceneEditor
import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand'


type Vector3 = {
    x: number;
    y: number;
    z: number;
};

type Object3DState = {
    position: Vector3;
    scale: Vector3;
    rotation: Vector3;
    setPosition: (position: keyof Vector3, value: string) => void;
    setSize: (size: keyof Vector3, value: string) => void;
};



export interface Object3DProps {
    position: Vector3;
    scale: Vector3;
    rotation: Vector3;
}

export type Object3DStore = ReturnType<typeof createObject3DStore>

export const createObject3DStore = (initProps?: Partial<Object3DProps>) => {
    const DEFAULT_PROPS: Object3DProps = {
        position: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
        rotation: { x: 0, y: 0, z: 0 },
    }
    return createStore<Object3DState>()((set) => ({
        ...DEFAULT_PROPS,
        ...initProps,
        setPosition: (dimension: keyof Vector3, value: string) =>
            set((state) => ({
                position: { ...state.position, [dimension]: parseFloat(value) },
            })),
        setSize: (dimension: keyof Vector3, value: string) =>
            set((state) => ({
                scale: { ...state.scale, [dimension]: parseFloat(value) },
            })),
    }))
}
export const Object3DContext = createContext<Object3DStore | null>(null)

export function useObject3DContext<T>(selector: (state: Object3DState) => T): T {
    const store = useContext(Object3DContext)
    if (!store) throw new Error('Missing BearContext.Provider in the tree')
    return useStore(store, selector)
}
import React, { useState } from 'react';
import { Button, Input, Box, Text, VStack, Video } from '@chakra-ui/react';

const VideoUpload = ({ setFile, src, file }) => {

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
           setFile(file)
        }
    };

    return (
        <VStack spacing={4}>
            <Input
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
                hidden
                id="video-upload"
            />
            <Box>
                <label htmlFor="video-upload">
                    <Button as="span" size="md" cursor="pointer">
                        Upload Video
                    </Button>
                </label>
            </Box>
            {file && (
                <Text>File: {file.name}</Text>
            )}
            {src && (
                <Box boxSize="300px" borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <video controls width="100%">
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            )}
        </VStack>
    );
};

export default VideoUpload;

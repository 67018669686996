import React, { useState, useEffect } from 'react';
import { Select } from '@chakra-ui/react';
import Events from '../../../lib/Events';

const cameraOptions = [
    { value: 'perspective', event: 'cameraperspectivetoggle', payload: null, label: 'Perspective' },
    { value: 'ortholeft', event: 'cameraorthographictoggle', payload: 'left', label: 'Left View' },
    { value: 'orthoright', event: 'cameraorthographictoggle', payload: 'right', label: 'Right View' },
    { value: 'orthotop', event: 'cameraorthographictoggle', payload: 'top', label: 'Top View' },
    { value: 'orthobottom', event: 'cameraorthographictoggle', payload: 'bottom', label: 'Bottom View' },
    { value: 'orthoback', event: 'cameraorthographictoggle', payload: 'back', label: 'Back View' },
    { value: 'orthofront', event: 'cameraorthographictoggle', payload: 'front', label: 'Front View' }
];

const CameraToolbar = () => {
    const [selectedCamera, setSelectedCamera] = useState(cameraOptions[0].value);

    useEffect(() => {
        const handleCameraToggle = (data) => {
            if (data.value !== selectedCamera) {
                setSelectedCamera(data.value);
            }
        };
        Events.on('cameratoggle', handleCameraToggle);
        return () => {
            Events.off('cameratoggle', handleCameraToggle);
        };
    }, [selectedCamera]);

    const handleChange = (event) => {
        const option = cameraOptions.find(opt => opt.value === event.target.value);
        if (option) {
            setSelectedCamera(option.value);
            Events.emit(option.event, option.payload);
        }
    };

    return (
        <Select onChange={handleChange} value={selectedCamera} placeholder="Select camera view">
            {cameraOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </Select>
    );
};
export default CameraToolbar
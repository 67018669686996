import React, { useState } from 'react';
import { Box, Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { Vector3Input } from './vector3-input';
import { Item, useEditorStore } from '../stores/editor-store';
import { Rotation3Input } from './rotation3-input';
import Events from '../lib/Events';
export const TAB_INDEXES = [
    "translate",
    "scale",
    "rotate"
]
const Object3DEditor = ({ item }: { item: Item }) => {
    const updateItem = useEditorStore(state => state.updateItem);
    const { position, scale, id, rotation } = item;
    const editorTab = useEditorStore(state => state.editorTab)
    const setEditorTab = useEditorStore(state => state.setEditorTab)
    const handleTabsChange = (index: number) => {
        console.log(editorTab, "TAB", index)
        Events.emit('transformmodechange', TAB_INDEXES[index]);

        setEditorTab(index);
    };

    return (
        <Box p={5} bg={"transparent"}>
            <Tabs isFitted variant="enclosed" background={"transparent"} index={editorTab} onChange={handleTabsChange}>
                <TabList mb="1em">
                    <Tab>Position</Tab>
                    <Tab>Scale</Tab>
                    <Tab>Rotation</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {(['x', 'y', 'z'] as (keyof typeof position)[]).map((dim) => (
                            <Vector3Input
                                key={dim}
                                label={dim.toUpperCase()}
                                value={position[dim]}
                                onChange={(value) => {
                                    const newValue = parseFloat(value);
                                    if (!isNaN(newValue)) {
                                        const newPosition = {
                                            ...position,
                                            [dim]: newValue,
                                        };
                                        updateItem(id, { position: newPosition });
                                    }
                                }}
                            />
                        ))}
                    </TabPanel>
                    <TabPanel>
                        {(['x', 'y', 'z'] as (keyof typeof scale)[]).map((dim) => (
                            <Vector3Input
                                key={dim}
                                label={dim.toUpperCase()}
                                value={scale[dim]}
                                onChange={(value) => {
                                    const newSize = {
                                        ...scale,
                                        [dim]: parseFloat(value),
                                    };
                                    updateItem(id, { scale: newSize });
                                }}
                            />
                        ))}
                    </TabPanel>
                    <TabPanel>
                        {(['x', 'y', 'z'] as (keyof typeof rotation)[]).map((dim) => (
                            <Rotation3Input
                                key={dim}
                                label={dim.toUpperCase()}
                                value={rotation[dim]}
                                onChange={(value) => {
                                    const newRotation = {
                                        ...rotation,
                                        [dim]: parseFloat(value),
                                    };
                                    updateItem(id, { rotation: newRotation });
                                }}
                            />
                        ))}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default Object3DEditor;

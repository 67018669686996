import React, { useEffect, useState } from "react";
import { Button, Text, VStack } from "@chakra-ui/react";
import { Item, useEditorStore } from "../../../stores/editor-store";

export default function ModelControls({ item }: {item: Item}) {
    const [playAnimation, setPlayAnimation] = useState(false);
    const [isPlaying, setIsPlaying] = useEditorStore(state => ([state.isScenePlaying, state.setIsScenePlaying]));


    useEffect(() => {
        const model = document.getElementById(item.id)
        if (model) {
            console.dir(model)
            const animationMixer = model.components['animation-mixer'];
            console.log(animationMixer, "MIXER")
            if (playAnimation) {
                if(!isPlaying){
                    setIsPlaying(true)
                }
                model.setAttribute('animation-mixer', 'timeScale: 1.0;');
                model.setAttribute('animation-mixer', 'clip: c;');
                animationMixer.play();
                model.addEventListener("animation-finished", ()=>{
                    console.log("FINISHED")
                })
                model.addEventListener("animation-loop", () => {
                    console.log("FINISHED")
                })
            } else {
                animationMixer.pause();
            }
        }
    }, [playAnimation]);

    const toggleAnimation = () => setPlayAnimation(!playAnimation);
    return (
        <VStack>
            <Button onClick={toggleAnimation} colorScheme="blue">
                {playAnimation ? "Pause" : "Play"}
            </Button>
            <Text>
                {playAnimation ? "Animation is playing" : "Animation is paused"}
            </Text>
        </VStack>
    );
}

import React, { useMemo } from "react"
import { Scene, Entity } from "aframe-react"
import { useEditorStore } from "../stores/editor-store"

export default function Viewer() {
    const itemsMap = useEditorStore(state => state.items)
    const targetImageUrl = useEditorStore(state => state.targetImageUrl)
    const items = Object.values(itemsMap)


    const { videoAssets, imageAssets, modelAssets } = useMemo(() => {
        const videoAssets = items
            .filter(item => item.type === 'videoPlane')
            .map(item => (
                <video id={`asset-${item.id}`} key={item.id} src={item.src} autoPlay playsInline muted />
            ));

        const imageAssets = items
            .filter(item => item.type === 'imagePlane')
            .map(item => (
                <img id={`asset-${item.id}`} key={item.id} src={item.src} alt="" />
            ));

        const modelAssets = items
            .filter(item => item.type === '3dObject')
            .map(item => (
                <a-asset-item id={`asset-${item.id}`} key={item.id} src={item.src} alt=""></a-asset-item>
            ));

        return { videoAssets, imageAssets, modelAssets };
    }, [items]);

    console.log(items, "URL")
    const imagePlanes = useMemo(() => items.filter(item => item.type === "imagePlane"), [items]);
    const videoPlanes = useMemo(() => items.filter(item => item.type === "videoPlane"), [items]);
    const object3Ds = items.filter(item => item.type === "3dObject") //useMemo(() => items.filter(item => item.type === "3dObject"), [items]);
    console.log(object3Ds, "OBJECTS")


    return (
        <Scene embedded={true} width="100%" height="100%" >
            <a-assets>
                {videoAssets}
                {imageAssets}
                {modelAssets}
                {targetImageUrl && <img id={"target-image"} src={targetImageUrl} alt="Dynamic" />}

            </a-assets>
            {/* <Entity primitive="a-grid" material={{ color: '#CCC' }} geometry={{ height: 20, width: 20 }} rotation={{ x: -90, y: 0, z: 0 }} /> */}

            <Entity geometry={{ primitive: 'box' }} material={{ color: 'red' }} position={{ x: 0, y: 0, z: -5 }} />
            <Entity
                geometry={{ primitive: 'box', height: 2, width: 3, depth: 0.1 }}
                material={{ color: '#888' }}
                position={{ x: 0, y: 1, z: -2 }}
                selectable={false}
                id="wall"
            >

                {targetImageUrl && (
                    <Entity
                        geometry={{ primitive: 'plane', height: 1, width: 1.5 }}
                        material={{ src: '#target-image' }}
                        position={{ x: 0, y: 0, z: 0.1 }}
                        selectable="false"
                    >
                        {videoPlanes.map(({ scale, position, id, rotation }) => {
                            return (
                                <Entity
                                    id={id}
                                    key={id}
                                    geometry={{ primitive: 'plane', height: scale.y, width: scale.x }}
                                    material={{ src: `#asset-${id}` }}
                                    position={{ x: position.x, y: position.y, z: position.z }}
                                    rotation={rotation}
                                />
                            )
                        })}
                        {imagePlanes.map(({ position, scale, id, rotation }) => {
                            return (
                                <Entity
                                    id={id}
                                    key={id}
                                    geometry={{ primitive: 'plane', height: scale.y, width: scale.x }}
                                    material={{ src: `#asset-${id}` }}
                                    position={position}
                                    rotation={rotation}
                                />
                            )
                        })}
                        {object3Ds.map(({ id, src, position, rotation, scale }) => (
                            <a-entity
                                id={id}
                                gltf-model={`url(${src})`}
                                position={`${position.x} ${position.y} ${position.z}`}
                                rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
                                scale={`${scale.x} ${scale.y} ${scale.z}`}
                                animation-mixer="clip: idle; loop: repeat; useRegExp: true"
                            ></a-entity>
                        ))}
                    </Entity>
                )}


            </Entity>
        </Scene>
    )
}
import React, { useEffect } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { useEditorStore } from '../../../stores/editor-store';

const ScenePlayButton = () => {
    const [isPlaying, setIsPlaying] = useEditorStore(state => ([state.isScenePlaying, state.setIsScenePlaying]));
    useEffect(() => {
        if (isPlaying) {
            AFRAME.scenes[0].isPlaying = false;
            AFRAME.scenes[0].play();
            return;
        }
        AFRAME.scenes[0].pause();
        AFRAME.scenes[0].isPlaying = true;
    }, [isPlaying])


    const toggleScenePlaying = () => {
        setIsPlaying(!isPlaying);

    };

    return (
        <IconButton
            aria-label={isPlaying ? 'Pause scene' : 'Play scene'}
            icon={isPlaying ? <FaPause /> : <FaPlay />}
            onClick={toggleScenePlaying}
            size="sm"
            colorScheme="teal"
        />
    );
};

export default ScenePlayButton

import { VStack, Image, Input } from "@chakra-ui/react";
import { useEditorStore } from "../../../stores/editor-store";

export default function TargetUploader(){
    const setImageTarget = useEditorStore(state => state.setImage);
    const setTargetImageUrl = useEditorStore(state => state.setTargetImageUrl);
    const targetImageUrl = useEditorStore(state => state.targetImageUrl);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            if (targetImageUrl){
                setTargetImageUrl("")
                return setTimeout(()=>{
                    setImageTarget(file)
                }, 100)
            }
            setImageTarget(file);
        }
    };
    return (<VStack spacing={4} align={"flex-end"}>
        <Input type="file" accept="image/*" onChange={handleImageChange}/>
        {targetImageUrl && <Image src={targetImageUrl} alt="Selected image preview" maxH="md" />}
    </VStack>)
}
import React, { useRef, useState } from 'react';
import { Box, Text, Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, IconButton } from '@chakra-ui/react';
import { useEditorStore } from '../../../stores/editor-store';
import { FaSave, FaTimes } from 'react-icons/fa';

const TopBanner = ({ isBig }) => {
    const file = useEditorStore(state => state.targetImageFile);
    const editorWidth = isBig ? "80dvw" : "95dvw";

    // State for the AlertDialog visibility
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();

    // Handler for the close button click
    const closeEditor = () => {
        setIsOpen(true);
    };

    return (
        <Box
            position="absolute"
            top="0"
            right="0"
            w={editorWidth}
            p={2}
            zIndex={2}
            bg="rgba(255, 255, 255, 0.6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {file && (
                <>
                    <Text fontSize="lg" fontWeight="bold">Target Image: {file.name}</Text>
                    <Button
                        rightIcon={<FaSave />}
                        position="absolute"
                        right="100px"
                        colorScheme="green"
                    >
                        Save & Publish
                    </Button>
                    <IconButton
                        icon={<FaTimes />}
                        position="absolute"
                        right="10px"
                        colorScheme="red"
                        onClick={closeEditor}
                    />
                    <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                    >
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                    Close Editor
                                </AlertDialogHeader>
                                <AlertDialogBody>
                                    Do you really want to close the app? All changes will be lost.
                                </AlertDialogBody>
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="red" onClick={() => {
                                        // Add the function to actually close the editor
                                        console.log("Editor closed"); // Placeholder action
                                        onClose();
                                    }} ml={3}>
                                        Close
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </>
            )}
        </Box>
    );
};

export default TopBanner;

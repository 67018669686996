// ItemView.tsx
import React from "react";
import { HStack, Text, Icon } from "@chakra-ui/react";
import { Item } from "../../../stores/editor-store";

const ItemView: React.FC<{ item: Item }> = ({ item }) => {
    return (
        <HStack spacing={4} width={"100%"}>
            <Text>{item.type} - {item.id}</Text>
        </HStack>
    );
};

export default ItemView;

import React, { useEffect, useState } from "react";
import { Button, Text, VStack, Icon } from "@chakra-ui/react";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md"; // Import mute/unmute icons

export default function VideoControls({ item }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);  // State to handle mute status

    useEffect(() => {
        const videoSelector = `#asset-${item.id}`;
        const videoElement = document.querySelector(videoSelector);

        if (!videoElement) {
            console.error("Video element not found");
            return;
        }

        const onVideoEnd = () => setIsPlaying(false);
        videoElement.addEventListener('ended', onVideoEnd);

        return () => {
            videoElement.removeEventListener('ended', onVideoEnd);
        };
    }, [item.id]);

    const handlePlayPause = () => {
        const videoSelector = `#asset-${item.id}`;
        const videoElement = document.querySelector(videoSelector);

        if (videoElement) {
            if (isPlaying) {
                videoElement.pause();
            } else {
                videoElement.play();
            }
            setIsPlaying(!isPlaying);
        } else {
            console.error("Video element not found");
        }
    };

    const toggleMute = () => {
        const videoSelector = `#asset-${item.id}`;
        const videoElement = document.querySelector(videoSelector);

        if (videoElement) {
            videoElement.muted = !videoElement.muted;  // Toggle the muted property
            setIsMuted(!isMuted);  // Update the mute state
        } else {
            console.error("Video element not found");
        }
    };

    return (
        <VStack>
            <Button onClick={handlePlayPause} colorScheme="blue">
                {isPlaying ? "Pause" : "Play"}
            </Button>
            <Button onClick={toggleMute} colorScheme="blue">
                <Icon as={isMuted ? MdVolumeOff : MdVolumeUp} />
            </Button>
            <Text>
                {isPlaying ? "Video is playing" : "Video is paused"}
            </Text>
            <Text>
                {isMuted ? "Sound is off" : "Sound is on"}
            </Text>
        </VStack>
    );
}

import React, { useState, useEffect } from 'react';
import { IconButton, Checkbox, Flex, Box } from '@chakra-ui/react';
import { FaArrowsAlt, FaArrowAltCircleLeft, FaCompressAlt } from 'react-icons/fa';
import Events from '../../../lib/Events';
import { useEditorStore } from '../../../stores/editor-store';
import { TAB_INDEXES } from '../../position-editor';

const transformButtons = [
    { value: 'translate', icon: FaArrowsAlt },
    { value: 'rotate', icon: FaArrowAltCircleLeft },
    { value: 'scale', icon: FaCompressAlt }
];

const TransformToolbar = () => {
    const [selectedTransform, setSelectedTransform] = useState('translate');
    const [localSpace, setLocalSpace] = useState(false);

    useEffect(() => {
        const handleTransformChange = (mode) => {
            console.log(mode, "MODE")
            setSelectedTransform(mode);
            useEditorStore.getState().setEditorTab(TAB_INDEXES[mode])
        };
        Events.on('transformmodechange', handleTransformChange);
        return () => {
            Events.off('transformmodechange', handleTransformChange);
        };
    }, []);

    const handleTransformClick = (mode) => {
        setSelectedTransform(mode);
        Events.emit('transformmodechange', mode);
    };

    const handleLocalChange = () => {
        const newLocalSpace = !localSpace;
        setLocalSpace(newLocalSpace);
        Events.emit('transformspacechanged', newLocalSpace ? 'local' : 'world');
    };

    return (
        <Flex align="center" gap="2">
            {transformButtons.map((button) => (
                <IconButton
                    key={button.value}
                    aria-label={button.value}
                    icon={<Box as={button.icon} />}
                    onClick={() => handleTransformClick(button.value)}
                    colorScheme={selectedTransform === button.value ? 'teal' : 'gray'}
                />
            ))}
            <Checkbox
                isChecked={localSpace}
                onChange={handleLocalChange}
                isDisabled={selectedTransform === 'scale'}
            >
                Local
            </Checkbox>
        </Flex>
    );
};
export default TransformToolbar
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const { customAlphabet } = require('nanoid');
// Define a custom alphabet with only letters
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
// Define the length of the ID
const length = 6;
// Create a nanoid generator with the custom alphabet and length
const nanoid = customAlphabet(alphabet, length);

// Define a type for the item position and scale
type Position = {
    x: number;
    y: number;
    z: number;
};

type Size = Position; // Using Position type as both have x, y, and z
type Rotation = Position; // Using Position type as both have x, y, and z

// Define a type for the item base attributes
interface ItemBase {
    id: string;
    type: 'imagePlane' | 'videoPlane' | '3dObject';
    position: Position;
    scale: Size;
    rotation: Rotation;
    src: string;
}

// Extend the ItemBase for future scalability
export type Item = ItemBase;

// Define the store's state
interface EditorState {
    targetImageFile?: File;
    targetImageUrl?: string;
    showStartModal: boolean;
    items: Record<string, Item>;
    selectedItemId: string | null;
    isScenePlaying: boolean;
    isSceneLoaded: boolean;
    editorTab: "scale" | "translate" | "rotate"
    addItem: (item: Omit<Item, 'id'>) => void;
    updateItem: (id: string, changes: Partial<Omit<Item, 'id'>>) => void;
    deleteItem: (id: string) => void;
    setImage: (imageFile: File) => Promise<void>;
    setTargetImageUrl: (url?: string) => void;
    setShowStartModal: (showStartModal: boolean) => void;
    selectItem: (itemId: string) => void;
    setIsScenePlaying: (isScenePlaying: boolean) => void;
    setIsSceneLoaded: (isSceneLoaded: boolean) => void;
    deselectItem: () => void;
    setEditorTab: (editorTab: "scale" | "translate" | "rotate") => void;
}

// Create the Zustand store with immer middleware
export const useEditorStore = create<EditorState>()(immer((set) => ({
    items: {},
    showStartModal: true,
    selectedItemId: null,
    isScenePlaying: true,
    isSceneLoaded: false,
    editorTab: "translate",//
    addItem: (item) => {
        const id = nanoid();
        set((state) => ({
            items: {
                ...state.items,
                [id]: {
                    id,
                    ...item,
                },
            },
        }));
    },
    updateItem: (id, changes) => set((state) => {
        // Ensure the item exists before attempting to update
        if (state.items[id]) {
            state.items[id] = {
                ...state.items[id],
                ...changes,
                position: changes.position ? { ...state.items[id].position, ...changes.position } : state.items[id].position,
                scale: changes.scale ? { ...state.items[id].scale, ...changes.scale } : state.items[id].scale,
                rotation: changes.rotation ? { ...state.items[id].rotation, ...changes.rotation } : state.items[id].rotation,
            };
        }
    }),
    deleteItem: (id) => set((state) => {
        // Utilize the delete operator for removing an item by id 
        delete state.items[id];
    }),
    setImage: async (imageFile) => {
        const url = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result?.toString());
            };
            reader.readAsDataURL(imageFile);
        });
        return set((state) => ({
            targetImageFile: imageFile,
            targetImageUrl: url!,
        }));
    },
    setShowStartModal: (showStartModal) => set(state => ({ showStartModal })),
    setTargetImageUrl: (targetImageUrl) => set(state => ({ targetImageUrl })),
    selectItem: (selectedItemId) => set(state => ({ selectedItemId })),
    deselectItem: () => set((state) => ({ selectedItemId: null })),
    setIsScenePlaying: (isScenePlaying) => set(state => ({ isScenePlaying })),
    setIsSceneLoaded: (isSceneLoaded) => set(state => ({ isSceneLoaded })),
    setEditorTab: (editorTab) => set((state) => ({ editorTab: editorTab }))
})));

import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Input,
    Image,
    VStack,
    useDisclosure
} from '@chakra-ui/react';
import { useEditorStore } from '../stores/editor-store';
import TargetUploader from './controls/target-uploader/target-uploader';

const StartScreenModal: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const targetImageUrl = useEditorStore(state => state.targetImageUrl);
    const setShowStartModal = useEditorStore(state => state.setShowStartModal);

   
    const handleClose = () => {
        onClose();
        // Additional logic to handle after the modal is closed, if necessary.
        setShowStartModal(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="full" isCentered>
            <ModalOverlay />
            <ModalContent m={0}>
                <ModalHeader>Add target image to start building your scene</ModalHeader>
                <ModalBody>
                    <TargetUploader/>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleClose} isDisabled={!targetImageUrl}>
                        Start Building
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default StartScreenModal;

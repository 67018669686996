import React, { useState } from 'react';
import { Button, Input, Box, Text, VStack, useToast } from '@chakra-ui/react';

const GLTFPreview = ({ modelUrl }) => {
    return (
        <Box height="500px" width="100%">
            <a-scene embedded>
                <a-sky color="#ECECEC"></a-sky>
                <a-entity
                    position="0 0 -2"
                    gltf-model={modelUrl}
                    animation-mixer="clip: c; loop: repeat; useRegExp: true"
                ></a-entity>
                <a-camera position="0 0 0"></a-camera>
            </a-scene>
        </Box>
    );
};

const ModelUpload = ({ setFile, file, src, setModelUrl, setIsUploading, isUploading }: any) => {
    const toast = useToast();

    const handleModelChange = (event) => {
        const newFile = event.target.files[0];
        if (newFile) {
            setFile(newFile);
        }
    };

    const uploadModel = async () => {
        setIsUploading(true);
        const [fileName, fileExtension] = file.name.split(".");

        try {
            const fetchUrl = await fetch(
                "https://staging.api.shop.relive-ar.com/api/editor-upload-urls?key=pmYkRVk%26zsQdQbO@1s6sz5gU2H%2639!Ym25$nSHd8",
                {
                    method: "POST",
                    body: JSON.stringify({
                        fileName,
                        fileExtension,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            ).then(res => res.json());

            const uploadResponse = await fetch(fetchUrl.signedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            if (uploadResponse.ok) {
                setModelUrl(fetchUrl.finalUrl);
                toast({
                    title: "Upload Successful",
                    description: "Model has been uploaded successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error('Upload failed.');
            }
        } catch (error) {
            console.error('Upload failed.', error);
            toast({
                title: "Error",
                description: "Failed to upload model.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <VStack spacing={4}>
            {src && <GLTFPreview modelUrl={src} />}
            <Input
                type="file"
                accept=".gltf,.glb"
                onChange={handleModelChange}
                hidden
                id="model-upload"
            />
            <Box>
                <label htmlFor="model-upload">
                    <Button as="span" size="md" cursor="pointer" disabled={isUploading}>
                        {isUploading ? "Uploading..." : "Select Model"}
                    </Button>
                </label>
            </Box>
            {file && (
                <>
                    <Text>File: {file.name}</Text>
                    <Button
                        as="span"
                        size="md"
                        cursor="pointer"
                        onClick={uploadModel}
                        isLoading={isUploading}
                        loadingText="Uploading"
                        disabled={isUploading}
                    >
                        Upload Model
                    </Button>
                </>
            )}
        </VStack>
    );
};

export default ModelUpload;

import React, { useMemo, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    useDisclosure,
    VStack,
    Box,
    Heading,
    useToast,
    IconButton,
    Text
} from '@chakra-ui/react';
import RadioCardsGroup from './custom-radio-card';
import VideoUpload from './video-upload';
import ImageUpload from './image-upload';
import ModelUpload from './model-upload';
import { useObject3DContext } from '../stores/object3d-store';
import { useEditorStore } from '../stores/editor-store';
import { FaPlus } from 'react-icons/fa';

type ObjectSelection = 'videoPlane' | 'imagePlane' | '3dObject';

const ModalAndWizard: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState<ObjectSelection | null>("videoPlane");
    const [file, setFile] = useState<File | null>(null);
    const [modelUrl, setModelUrl] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);  // State to manage upload status
    const { position, scale, rotation } = useObject3DContext(state => ({
        position: state.position,
        scale: state.scale,
        rotation: state.rotation
    }));
    const addItem = useEditorStore(state => state.addItem);
    const toast = useToast();

    const src = useMemo(() => {
        if (modelUrl) {
            return modelUrl;
        }
        if (file) {
            return URL.createObjectURL(file);
        }
    }, [file, modelUrl]);

    const resetAndClose = () => {
        onClose();
        setStep(1);
        setFile(null);
        setModelUrl(null);
        setSelection(null);
        setIsUploading(false); // Reset uploading status on close
    };

    const handleSelectionChange = (nextValue: string) => {
        setSelection(nextValue as ObjectSelection);
    };

    const nextStep = () => {
        if (step === 2 && selection === "3dObject" && isUploading) {
            toast({
                title: "Upload in Progress",
                description: "Please wait until the model has been uploaded.",
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        } else {
            setStep(prev => prev + 1);
        }
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
    };

    const WizardStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <Heading size="md" mb={4}>Step 1: Select Item to Insert</Heading>
                        <RadioCardsGroup onChange={handleSelectionChange} value={selection} />
                    </>
                );
            case 2:
                return (
                    <>
                        <Heading size="md" mb={4}>Step 2: Configure Item</Heading>
                        <Box>
                            {selection && `Selected: ${selection}. Customize your selection here.`}
                            {selection === "videoPlane" && <VideoUpload setFile={setFile} src={src} file={file} />}
                            {selection === "imagePlane" && <ImageUpload setFile={setFile} src={src} file={file} />}
                            {selection === "3dObject" && <ModelUpload setFile={setFile} src={src} file={file} setModelUrl={setModelUrl} setIsUploading={setIsUploading} isUploading={isUploading} />}
                        </Box>
                    </>
                );
            case 3:
                return (
                    <>
                        <Heading size="md" mb={4}>Step 3: Insert Item</Heading>
                        <Box>
                            Confirmation step. Review your selection and submit.
                            <Button onClick={() => { addItem({ src, position, scale, rotation, type: selection }); resetAndClose(); }}>Submit</Button>
                        </Box>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Box
                px={4}  // Padding on the x-axis
                py={2}  // Padding on the y-axis
                width="full"  // Take the full width of its container
                display="flex"  // Use flexbox for layout
                alignItems="center"  // Align items vertically in the center
                justifyContent="space-between"  // Space between the items
            >
                <Text fontSize="md" fontWeight="bold">Entities</Text>
                <IconButton
                    aria-label="Configure AR settings"
                    icon={<FaPlus />}
                    onClick={onOpen}  // Handle toggle functionality
                    size="md"  // Small button size
                    colorScheme="green"
                />
            </Box>
            <Modal isOpen={isOpen} onClose={resetAndClose} size={"xxl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Wizard</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            <WizardStep />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        {step > 1 && <Button variant="ghost" onClick={prevStep} isDisabled={isUploading}>Back</Button>}
                        {step < 3 && <Button colorScheme="blue" onClick={nextStep} isDisabled={isUploading}>Next</Button>}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalAndWizard;

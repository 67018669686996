import { EditIcon, SettingsIcon } from "@chakra-ui/icons";
import { Box, Button, Collapse, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import FileUploadModal from "../target-uploader/target-upload-modal";

const SidebarHeader = ({ isOpen }) => {
    const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();

    return (

        <Collapse in={isOpen} animateOpacity style={{ overflowY: "scroll", width: "100%", position: "relative" }}>
            <Box
                px={4}  // Padding on the x-axis
                py={4}  // Padding on the y-axis
                width="full"  // Take the full width of its container
                display="flex"  // Use flexbox for layout
                alignItems="center"  // Align items vertically in the center
                justifyContent="space-between"  // Space between the items
            >
                <Text fontSize="md" fontWeight="bold">Configure AR</Text>
                <IconButton
                    aria-label="Configure AR settings"
                    icon={<SettingsIcon />}
                    // onClick={onToggle}  // Handle toggle functionality
                    size="sm"  // Small button size
                />
            </Box>
            <Box height={"40vh"} background={"transparent"}>
                <Button
                    rightIcon={<EditIcon />}
                    onClick={onOpen}
                    // variant="ghost"
                >
                    Change Target
                </Button>
                <FileUploadModal isOpen={isModalOpen} onClose={onClose} />

            </Box>

        </Collapse>
    );
};

export default SidebarHeader
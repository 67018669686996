// Vector3Input.tsx adjustment
import React from 'react';
import { FormControl, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';

type Props = {
    label: string;
    value: number;
    onChange: (value: string) => void; // Change to handle string input
};

export const Rotation3Input: React.FC<Props> = ({ label, value, onChange }) => {
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <NumberInput value={value} precision={3} step={1} onChange={onChange}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </FormControl>

    );
};

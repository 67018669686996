import React, { useState } from 'react';
import { Box, HStack, IconButton, useColorModeValue, VStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Item, useEditorStore } from '../../stores/editor-store';
import ItemView from './item-view/item-view';

const ItemList: React.FC = () => {
    const itemsMap = useEditorStore(state => state.items);
    const deleteItem = useEditorStore(state => state.deleteItem);
    const selectItem = useEditorStore(state => state.selectItem);
    const deselectItem = useEditorStore(state => state.deselectItem);
    const selectedItemId = useEditorStore(state => state.selectedItemId);
    const items = Object.values(itemsMap);
    const selectedBg = useColorModeValue('blue.100', 'blue.600');
    const hoverBg = useColorModeValue('gray.100', 'gray.700');  // Added hover background color

    // State for handling the deletion confirmation dialog
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentItemToDelete, setCurrentItemToDelete] = useState<string | null>(null);

    const handleEdit = (item: Item) => {
        if (item.id === selectedItemId) {
            deselectItem()
            AFRAME.INSPECTOR.selectEntity(null);
            return
        }
        const element = document.getElementById(item.id);
        selectItem(item.id);
        AFRAME.INSPECTOR.selectEntity(element)
    };

    const onCloseDialog = () => {
        setIsDialogOpen(false);
        setCurrentItemToDelete(null);
        AFRAME.INSPECTOR.selectEntity(null);
    };

    const onDeleteConfirm = () => {
        if (currentItemToDelete) {
            deleteItem(currentItemToDelete);
            onCloseDialog();
        }
    };

    return (
        <>
            <VStack spacing={2} align="stretch" w="100%" h="100%">
                {items.map((item) => (
                    <Box
                        key={item.id}
                        p={2}
                        borderWidth="1px"
                        borderRadius="md"
                        bg={selectedItemId === item.id ? selectedBg : "transparent"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={(e) =>{
                            handleEdit(item)
                        }}
                        cursor="pointer"  // Cursor pointer on hover
                        _hover={{ bg: hoverBg, transform: 'scale(1.01)' }}  // Added hover effect
                        transition="all 0.2s ease-in-out"  // Smooth transition for hover effects
                    >
                        <ItemView item={item} />
                        <HStack>
                            <IconButton
                                icon={<DeleteIcon />}
                                aria-label="Delete"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevents the edit action when clicking the delete button
                                    setIsDialogOpen(true);
                                    setCurrentItemToDelete(item.id);
                                }}
                                colorScheme="red"
                                size="sm"
                            />
                        </HStack>
                    </Box>
                ))}
            </VStack>

            {/* AlertDialog for delete confirmation */}
            {currentItemToDelete && (
                <AlertDialog
                    isOpen={isDialogOpen}
                    leastDestructiveRef={undefined}
                    onClose={onCloseDialog}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Delete Item
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={onCloseDialog}>
                                    Cancel
                                </Button>
                                <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            )}
        </>
    );
};

export default ItemList;
